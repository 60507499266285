import React, {useState, useEffect} from "react"
import "./index.scss"
import { NavLink, useLocation} from "react-router-dom";
import OutsideClickHandler from 'react-outside-click-handler';

import CaretDown from 'icons/caret-down.js';
import CaretPlus from 'icons/caret-plus.js';

import useScrollToHash from "hooks/useScrollToHash.js"

import { NavHashLink, HashLink } from 'react-router-hash-link';

export default function NavBar() {
  const location = useLocation();
  const [color, setColor] = useState('lightblue');
  const [portfolioDropdown,setPortfolioDropdown]=useState(false);    
  const [menuDropdown,setMenuDropdown]=useState(false);   
  const [mobileOptionDropdownPortfolio,setMobileOptionDropdownPortfolio]=useState(false);   
  const [mobileOptionDropdownAbout,setMobileOptionDropdownAbout]=useState(false);   
  const [mobileOptionDropdownNews,setMobileOptionDropdownNews]=useState(false);   


  useEffect(whatColor);

  function whatColor(){
    if (location.pathname.startsWith('/project')) {
      setColor('project-background-color');
    } else if (location.pathname.endsWith('/#approach')){
      setColor('approach-background-color');
    } else if (location.pathname.endsWith('/#leadership')){
      setColor('leadership-background-color');
    } else if (location.pathname.startsWith('/about')){
      setColor('about-background-color');
    } else if (location.pathname.startsWith('/contact')){
      setColor('contact-background-color');
    }  else if (location.pathname.startsWith('/portfolio')){
      setColor('portfolio-background-color');
    }  else if (location.pathname.startsWith('/people')){
      setColor('people-background-color');
    }  else if (location.pathname.startsWith('/category/libraries')){
      setColor('project-type-background-color');
    }  else if (location.pathname.startsWith('/category/government')){
      setColor('project-type-background-color');
    } else if (location.pathname.startsWith('/category/corporate')){
      setColor('project-type-background-color');
    } else if (location.pathname.startsWith('/category/religious')){
      setColor('project-type-background-color');
    } else if (location.pathname.startsWith('/category/residential')){
      setColor('project-type-background-color');
    } else if (location.pathname.startsWith('/category/educational')){
      setColor('project-type-background-color');
    } else {
      setColor('default-background-color');
    }
  }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -94; 

    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 

  }


  function delayScrollWithOffset(el) {
    setTimeout(
      function(){ 
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -94; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
      },
      300);
  }



 

  const scrollWithOffsetInstant = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -94; 

    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'instant' }); 

  }


  return (

    <header className={`header ${color}`} >
      <div className="header-inner ">
        <nav className="nav-contents">

          <div className="nav-logo">
            <NavLink 
              to="/" exact 
              activeClassName="nav-active"
              className="nav-option-logo"
            >

              <div className="logo">
          
                  <img src={'/assets/ai-logo-mobile.svg'} />
                
              </div>

            </NavLink>
          </div>
  
          <OutsideClickHandler
            onOutsideClick={() => {
              setMobileOptionDropdownAbout(false)
              setMobileOptionDropdownPortfolio(false)
              setMobileOptionDropdownNews(false)
              setMenuDropdown(false)
            }}
          >
              <div className="nav-menu-mobile-button" onClick={
                  ()=>{
                    setMenuDropdown(!menuDropdown)
                    // setMobileOptionDropdownAbout(false)
                    // setMobileOptionDropdownPortfolio(false)
                  }
                }>
                <span className={`plus ${menuDropdown ? 'rotate-45' : ''}`}>+</span> 
              </div>

              <div 
                className={`mobile-menu ${menuDropdown ? 'expand' : ''}`}  
               
              >
                <div 
                  className="item-and-caret-and-dropdown"
                  // onMouseLeave={
                  //   ()=>{
                  //     setMobileOptionDropdownPortfolio(false)
                  //     console.log('mobileOptionDropdownPortfolio', mobileOptionDropdownPortfolio)
                  //     // setMenuDropdown(true)
                  //     // setMobileOptionDropdownAbout(false)
                  //   }
                  // } 
                >
                  <div className="item-and-caret">
                    <div 
                      className={`item-and-caret-item ${menuDropdown ? 'expand ' : ''}`}  
                      onClick={
                        ()=>{
                          // setMenuDropdown(menuDropdown ? false : true)
                          setMobileOptionDropdownAbout(false)
                          setMobileOptionDropdownNews(false)
                          setMobileOptionDropdownPortfolio(!mobileOptionDropdownPortfolio)
                        }
                      } 
                      // onMouseEnter={
                      //   ()=>{
                      //     setMobileOptionDropdownPortfolio(true)
                      //     console.log('mobileOptionDropdownPortfolio', mobileOptionDropdownPortfolio)
                      //     // setMenuDropdown(true)
                      //     // setMobileOptionDropdownAbout(false)
                      //   }
                      // } 
                    >
                      <div 
                      scroll={scrollWithOffsetInstant}
                        to="/portfolio" 
                        activeClassName="nav-active mobile-option-active "
                        className="nav-option mobile-option nav-one"
                      >
                        Portfolio
                      </div>
                    </div>
                    
                    <div 
                      className="caret-wrap"
                    >
                      {mobileOptionDropdownPortfolio ?
                      <CaretDown  className={`caret`} />
                      :
                      <CaretPlus  className={`caret`} />
                      }
                    
                    </div>
            
                  </div>
                  
                 

                  <div 
                    className={`mobile-option-dropdown ${mobileOptionDropdownPortfolio ? 'unroll' : 'rolled'}`}  
                  >
                      
                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownPortfolio(false)
                        }} 
                      >
                        <NavLink 
                          to="/portfolio" 
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item first-one"
                        >
                          Featured
                        </NavLink>
                      </div>
                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownPortfolio(false)
                       
                        }} 
                      >
                        <NavLink 
                          to="/category/libraries" 
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item"
                        >
                          Libraries
                        </NavLink>
                      </div>
                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownPortfolio(false)
                        }} 
                      >
                        <NavLink 
                          to="/category/government" 
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item "
                        >
                          Government
                        </NavLink>
                      </div>
                      <div 
                        className={`${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownPortfolio(false)
                        }} 
                      >
                        <NavLink 
                          to="/category/corporate" 
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item "
                        >
                          Corporate
                        </NavLink>
                      </div>
                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownPortfolio(false)
                        }} 
                      >
                        <NavLink 
                          to="/category/religious" 
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item "
                        >
                          Religious
                        </NavLink>
                      </div>
                      <div 
                        className={`${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownPortfolio(false)
                        }} 
                      >
                        <NavLink 
                          to="/category/residential" 
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item "
                        >
                          Residential
                        </NavLink>
                      </div>
                      <div 
                        className={`${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownPortfolio(false)
                        }} 
                      >
                        <NavLink 
                          to="/category/educational" 
                          activeClassName="nav-active mobile-option-active dropdown-item-active "
                          className="nav-option mobile-option dropdown-item last-one"
                        >
                          Educational
                        </NavLink>
                      </div>                     
                  </div>
                 
                
                </div>



                <div className="item-and-caret-and-dropdown">
                  <div className="item-and-caret">
                    <div 
                      className={`item-and-caret-item ${menuDropdown ? 'expand' : ''}`}  
                      onClick={
                        ()=>{
                          // setMenuDropdown(false)
                          setMobileOptionDropdownNews(!mobileOptionDropdownNews)
                          setMobileOptionDropdownPortfolio(false)
                          setMobileOptionDropdownAbout(false)
                        }
                      } 
                    >
                      <div 
                        
                        to="/news#main" 
                        scroll={scrollWithOffsetInstant}
                        activeClassName="nav-active mobile-option-active "
                        className="nav-option mobile-option nav-one"
                      >
                        News
                      </div>
                    </div>
                    
                    <div 
                      onClick={
                        ()=>{
                          setMobileOptionDropdownNews(!mobileOptionDropdownNews)
                          setMobileOptionDropdownPortfolio(false)
                          setMobileOptionDropdownAbout(false)
                        }
                      }
                      className="caret-wrap"
                    >
                      {mobileOptionDropdownNews ?
                      <CaretDown  className={`caret`} />
                      :
                      <CaretPlus  className={`caret`} />
                      }
                    </div>
            
                  </div>
                  <div 
                    className={`mobile-option-dropdown ${mobileOptionDropdownNews ? 'unroll' : 'rolled'}`}  
                  >
                      
                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownNews(false)
                        }} 
                      >
                        <NavHashLink 
                         
                          to="/news#awards" 
                          scroll={delayScrollWithOffset}
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item "
                        >
                          Awards
                        </NavHashLink>
                      </div>

                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownNews(false)
                        }} 
                      >
                        <NavHashLink 
                         
                          to="/news#press-releases" 
                          scroll={delayScrollWithOffset}
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item first-one"
                        >
                          Press Releases
                        </NavHashLink>
                      </div>
                      
                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownNews(false)

                        }} 
                      >
                        <NavHashLink 
                        
                          to="/news#publications" 
                          scroll={delayScrollWithOffset}
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item "
                        >
                          Publications
                        </NavHashLink>
                      </div>
                      
                    
                  </div>
                </div>


                <div className="item-and-caret-and-dropdown">
                  <div className="item-and-caret">
                    <div 
                      className={`item-and-caret-item ${menuDropdown ? 'expand' : ''}`}  
                      onClick={
                        ()=>{
                          // setMenuDropdown(false)
                          setMobileOptionDropdownAbout(!mobileOptionDropdownAbout)
                          setMobileOptionDropdownPortfolio(false)
                          setMobileOptionDropdownNews(false)
                        }
                      } 
                    >
                      <div 
                        
                        to="/about#main" 
                        scroll={scrollWithOffsetInstant}
                        activeClassName="nav-active mobile-option-active "
                        className="nav-option mobile-option nav-one"
                      >
                        About
                      </div>
                    </div>
                    
                    <div 
                      onClick={
                        ()=>{
                          setMobileOptionDropdownAbout(!mobileOptionDropdownAbout)
                          setMobileOptionDropdownPortfolio(false)
                          setMobileOptionDropdownNews(false)
                        }
                      }
                      className="caret-wrap"
                    >
                      {mobileOptionDropdownAbout ?
                      <CaretDown  className={`caret`} />
                      :
                      <CaretPlus  className={`caret`} />
                      }
                    </div>
            
                  </div>
                  <div 
                    className={`mobile-option-dropdown ${mobileOptionDropdownAbout ? 'unroll' : 'rolled'}`}  
                  >
                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownAbout(false)
                        }} 
                      >
                        <NavHashLink 
                         
                          to="/about#main" 
                          scroll={delayScrollWithOffset}
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item first-one"
                        >
                          Introduction
                        </NavHashLink>
                      </div>

                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownAbout(false)
                        }} 
                      >
                        <NavHashLink 
                         
                          to="/about#approach" 
                          scroll={delayScrollWithOffset}
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item "
                        >
                          Approach
                        </NavHashLink>
                      </div>
                      <div 
                        className={` ${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownAbout(false)
                        }} 
                      >
                        <NavHashLink 
                        
                          to="/about#leadership" 
                          scroll={delayScrollWithOffset}
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item "
                        >
                          Leadership
                        </NavHashLink>
                      </div>

                      <div 
                        className={`${menuDropdown ? 'expand' : ''}`}  
                        onClick={()=>{
                          setMenuDropdown(!menuDropdown)
                          setMobileOptionDropdownAbout(false)
                        }} 
                      >
                        <NavHashLink 
            
                          
                          to="/about#testimonials" 
                          scroll={delayScrollWithOffset}
                          activeClassName="nav-active mobile-option-active dropdown-item-active  "
                          className="nav-option mobile-option dropdown-item last-one"
                        >
                          Testimonials
                        </NavHashLink>
                      </div>
                     
                    
                  </div>
                </div>

               

                <div 
                  className={`${menuDropdown ? 'expand' : ''}`}  
                  onClick={
                    ()=>{
                      window.location.reload(false);
                      setMenuDropdown(false)
                      setMobileOptionDropdownAbout(false)
                      setMobileOptionDropdownPortfolio(false)
                    }
                  } 
                >
                  <NavLink 
                    to="/contact" 
                    activeClassName="nav-active mobile-option-active "
                    className="nav-option mobile-option nav-one"
                  >
                    Contact
                  </NavLink>
                </div>
              </div>
            

          </OutsideClickHandler>

        </nav>
      </div>
    </header>
  );
}