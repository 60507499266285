import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "client.js";


import { Row, Col } from 'react-simple-flex-grid';

import {plus} from 'components/plus';
import LookDown from 'icons/look-down.js';

import { NavHashLink } from 'react-router-hash-link';

import "react-simple-flex-grid/lib/main.css";
import BlockContent from "@sanity/block-content-to-react";

import HeaderBar from "components/header-bar";

import imageUrlBuilder from "@sanity/image-url";
import "./index.scss";


const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}


export default function ProjectType() {
  const [projectTypeData, setProjectType] = useState(null);
  const { slug } = useParams();
  const [readMore,setReadMore]=useState(false);    
  const linkName=readMore?'READ LESS – ':'READ MORE ＋ '

  const scrollABit = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -94; 

    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 

  }



  useEffect(() => {
    sanityClient.fetch(`*[_type == "projecttype" && slug.current == "${slug}"] {
        title,
        slug,
        pageDescriptionHeader,
        pageDescriptionShort,
        pageDescriptionContinued,
        projectTypeClientsDescription,
        "projects": *[_type == "project" && projectType == "${slug}"] | order(order asc){title, slug, location, imagesGallery, _id},
        "clients": *[_type == "clients" && projectType == "${slug}"] | order(title asc) {title, location, description, _id}
      }
      `)
      .then((data) => setProjectType(data[0]))
      .catch(console.error);
        
      }, [slug]);
      // loading
  console.log(projectTypeData)
  if (!projectTypeData ) return (

    <main className="project-type-page-back">
      <section className="project-type-section fade-in">
        <div className="project-type-zone-1" style={{backgroundImage: `url("../assets/+-crossover.gif")`, opacity: 0.15, minHeight: 740}}> 
        </div>
      </section>
    </main>

  );
  
  
  else 
  console.log(projectTypeData.slug.current)

  return (
    <main className="project-type-page-back">
      <section className="project-type-section fade-in">




          <div className="project-type-zone-1" style={{backgroundImage: `url(../assets/back-plus.svg)`}}>
            <div className="header-and-desc">
            

            <div className="breadcrumbs description-project-type ">
              <Link 
                to={"/portfolio"} 
                key={'portfolio'}
              >
                <span> </span>
                <div className="breadcrumb">
                  Portfolio 
                </div>
                <span> </span>
              </Link>
              {'>'}
            </div>


              <HeaderBar className="header-bar-sticky" title={projectTypeData.title} color="dark"/>
              <div className="description-wrap">
                <div className='description-project-type project-type-header'>
                  {projectTypeData.pageDescriptionHeader}
                </div>
                <div className= 'description-project-type'>
                  <div className={readMore ? 'description-project-type-inner read-more-project-type border-top' : 'description-project-type-inner border-top'}>
                    <div className={'description margin-top-less-one'}>
                      <div  className="single-project-description-short">
                          <BlockContent 
                            blocks={projectTypeData.pageDescriptionShort} 
                            projectId="cv0gtuav" 
                            dataset="production"
                          />
                      </div>

                      { projectTypeData.pageDescriptionContinued && (
                        <div className="description-continued">

                        { readMore && 
                          <div  className='single-project-description-continued'>
                              <BlockContent 
                                blocks={projectTypeData.pageDescriptionContinued} 
                                projectId="cv0gtuav" 
                                dataset="production"
                              />
                            </div>
                                  
                          
                        }
              
                          <div className={readMore ? 'read-more-box disappear':'read-more-box'}>
                            <div className="read-more-link" onClick={()=>{setReadMore(!readMore)}}>
                              <span>{linkName}</span>
                            </div>
                            {/* <NavHashLink   
                              to={`/${projectTypeData.slug.current}/#clients`}
                              scroll={scrollABit}
                              >
                              <LookDown className="look-down-side"/>
                            </NavHashLink> */}
                          </div>
                        </div>
                      )}
                      
                    </div>
                  </div>
                  <div className={readMore ? 'description-overlay-project-type':'description-overlay-project-type disappear'} ></div> 
                </div>
                <div className={readMore ? 'read-more-box disappear-below-996 description-project-type':'read-more-box disappear disappear-below-996 description-project-type'}>
                  <div className="read-more-link" onClick={()=>{setReadMore(!readMore)}}>
                    <span>{linkName}</span>
                  </div>
                  {/* <NavHashLink   
                    to={`/${projectTypeData.slug.current}/#clients`}
                    scroll={scrollABit}
                    >
                    <LookDown className="look-down-side"/>
                  </NavHashLink> */}
                </div>
                <div className="description-project-type">
                  <NavHashLink   
                    to={`/category/${projectTypeData.slug.current}/#clients`}
                    scroll={scrollABit}
                    >
                    <LookDown className="look-down-side"/>
                  </NavHashLink>
                </div>
              </div>
            </div>
  

{/* featured gallery */}

            <div className="featured-panel fade-in-more">
              <Row gutter={0}>
                {projectTypeData && projectTypeData.projects.map((projects, index) => (
                  
                  <Col
                  
                    className="col-visible"
                    xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}
                    lg={{ span: 4 }} xl={{ span: 4 }}
                    key={projects._id}
                  >
                    <div className="project-card-wrap-wrap">
                      <Link 
                        className="project-card-wrap"
                        to={"/project/" + projects.slug.current} 
                        key={projects.slug.current}
                        >
                        <div
                          className="card-img"
                          key={projects._id}
                        >
                          <img 
                            src={urlFor(projects.imagesGallery[0]).url()}
                            alt={projects.imagesGallery[0].alt}
                            key={projects._id}
                            className="main-image-project-type"
                          />
                        </div>
                        <div className="card-description">
                            <h3>
                              {projects.title}
                            </h3>
                            <h4>
                              {projects.location}
                            </h4>
                          </div> 
                      </Link>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>


{/* featured gallery end  */}



          </div>


          <div className="project-type-zone-1 clients-section clients-bg-img">
            <div className="header-and-desc">
              <HeaderBar 
                className="header-bar-sticky clients-header" 
                color="medium" 
                id="clients"
                title={  slug == "libraries" ? 
                "Library Clients"
              :
              <div>
                {capitalizeFirstLetter(slug)} Clients 
              </div>
              } 
              />
              <div className="description-testimonials ">
                <div className="description-testimonials-inner">
             
                    <BlockContent 
                      blocks={projectTypeData.projectTypeClientsDescription}
                      projectId="cv0gtuav" 
                      dataset="production"
                    />
                 
                </div>
              </div>
            </div>
            <div className="featured-panel">
                

              <h1 className="client-type-title">
                <div className="client-type-title-blank"> </div>
              </h1>
              <Row gutter={0} >
                {projectTypeData && projectTypeData.clients.map((clients, index) => (
                  <Col
                    className="col-visible"
                    xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}
                    lg={{ span: 12}} xl={{ span: 12 }}
                    key={projectTypeData.clients[index].title}
                  >
                        <div style={{backgroundImage: `url("assets/back-plus.svg")`}} className={`clients ${index % 4 === 0 ? 'fourths' : index % 4 === 1 ? 'thirds' : index % 4 === 2 ? 'seconds' : 'ones'}`}>
                          <div className="clients-inner" >
                            <h3>
                              {projectTypeData.clients[index].title}
                            </h3>
                            <h4>
                              {projectTypeData.clients[index].location}<br/>
                            </h4>
                            <h4>
                             
                              { projectTypeData.clients[index].description ? 
                                <span
                                // className="italics"
                                >
                                <BlockContent 
                                  blocks={projectTypeData.clients[index].description} 
                                  projectId="cv0gtuav" 
                                  dataset="production"
                                  
                                />

                                </span>
                              : 
                                <div></div>
                              }
                            </h4>
                          </div>
         
                        </div> 
                  </Col>
                ))}
              </Row>









            </div>
          </div>

   
      </section>
    </main>
  );
}