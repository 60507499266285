import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Controller, Thumbs, Autoplay, EffectFade } from 'swiper';
import 'swiper/swiper-bundle.css';
import {CarouselProgress} from "components/carousel-progress/index.js";

import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";

import { NavHashLink, HashLink } from 'react-router-hash-link';

import {useMountEffect} from "hooks/useMountEffect";

import './index.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}

SwiperCore.use([Navigation, Pagination, Controller, Thumbs, Autoplay, EffectFade]);

function CarouselDemo() {
  const [currentSlide, setCurrentSlide] = useState(null);

  const [carousel, setCarousel] = useState(null);


// LISTEN FOR WIDTH
  const [carouselWidth, setCarouselWidth] = useState(window.innerWidth);
  const [carouselHeight, setCarouselHeight] = useState(500);

  function delayScrollWithOffset(el) {
    setTimeout(
      function(){ 
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -94; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
      },
      300);
  }

  function updateMedia() {
    if (window.innerWidth > 1400) {
      setCarouselWidth(1325);
      setCarouselHeight(700);
  
    } else 
    if (window.innerWidth > 768) {
      setCarouselWidth(window.innerWidth - 64);
      setCarouselHeight(600);
 
    } else {
      setCarouselWidth(window.innerWidth);
      setCarouselHeight(500);
 
    }
  };

  useEffect(() => {
    sanityClient.fetch(`*[_type == "carousel"] | order(order asc){
      image,
      caption,
      link,
    }`)
    .then((data) => setCarousel(data))
    .catch(console.error);
  }, []);

  // fetches updateMedia on LOAD and only ONCE
  useMountEffect(updateMedia);

  // fetches updateMedia on RESIZE every time window resizes
  useEffect(() => {


    window.addEventListener("resize", updateMedia);

    return () => {
      window.removeEventListener("resize", updateMedia);
    };
  });
//LISTEN FOR WIDTH - end




  //loading option
  if (!carousel) return <div> 
    
        <div className="dummy-image" style={{height: `700px`, width:`${carouselWidth}px`,}}>
          
        </div>
      </div>;

  return (
    <div className="fade-in-delay-more">
      <Swiper
        className="fade-in-delay"
        id="main"
        tag="section"
        wrapperTag="ul"
        loop={true}
        effect="fade"
        autoplay={{ 
          delay: 3800,
          disableOnInteraction: true,
        }}
        navigation
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides={true}
        onSlideChange={(swiper) => {
          setCurrentSlide(swiper.realIndex + 1)
        }}
      >
        {/* {slides} */}

{/*    
        {carousel && carousel.map((x, i) => (

            <SwiperSlide key={`slide-${i}`} tag="li" >

              <NavHashLink 
                to="/about#leadership" 
                scroll={delayScrollWithOffset}
                activeClassName="nav-active mobile-option-active dropdown-item-active  "
                className=""
              >
               

              <div className="image-wrap">
                <img
                  className="image-behave"
                  src={urlFor(carousel[i].image)
                    .height(carouselHeight)
                    .width(carouselWidth)
                    
                    
                    .url()} 
                    style={{ listStyle: 'none' }}
                    alt={`Slide ${i}`}
                    />

              </div>

              <div className="caption" >
                <div className="caption-text" >
                {carousel[i].caption}
                </div>
              </div>

              </NavHashLink>

            </SwiperSlide>
    
          ))
        }

         */}

{carousel && carousel.map((x, i) => (
  <SwiperSlide key={`slide-${i}`} tag="li">
    {carousel[i].link ? ( // Check if the carousel item has a 'link' property
      <NavHashLink 
        to={carousel[i].link} // Use the 'link' property as the 'to' prop
        scroll={delayScrollWithOffset}
        activeClassName="nav-active mobile-option-active dropdown-item-active"
        className=""
      >
        <div className="image-wrap">
          <img
            className="image-behave"
            src={urlFor(carousel[i].image)
              .height(carouselHeight)
              .width(carouselWidth)
              .url()} 
            style={{ listStyle: 'none' }}
            alt={`Slide ${i}`}
          />
        </div>
        <div className="caption" >
          <div className="caption-text" >
            {carousel[i].caption}
          </div>
        </div>
      </NavHashLink>
    ) : (
      <div>
        {/* Render something else if there is no 'link' property */}
        <div className="image-wrap">
          <img
            className="image-behave"
            src={urlFor(carousel[i].image)
              .height(carouselHeight)
              .width(carouselWidth)
              .url()} 
            style={{ listStyle: 'none' }}
            alt={`Slide ${i}`}
          />
        </div>
        <div className="caption" >
          <div className="caption-text" >
            {carousel[i].caption}
          </div>
        </div>
      </div>
    )}
  </SwiperSlide>
))}


      

      </Swiper>

      <CarouselProgress progress={currentSlide} total={carousel.length}/>

  </div>
  );
}

export default CarouselDemo;