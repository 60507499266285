import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "client.js";


import { Row, Col } from 'react-simple-flex-grid';

import "react-simple-flex-grid/lib/main.css";

import HeaderBar from "components/header-bar";

import BlockContent from "@sanity/block-content-to-react";

import imageUrlBuilder from "@sanity/image-url";
import "./index.scss";
import TestimonialsMap from "components/testimonials-map";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}

export default function Testimonials() {
  const [testimonialspage, setTestimonialspage] = useState(null);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "testimonialspage"]{
        intro,
      }
      `)
      .then((data) => setTestimonialspage(data))
      .catch(console.error);

  }, []);

  return (
    <main className="testimonial-page-back">
      <section className="portfolio-section fade-in">

          <div className="portfolio-zone-1" id="testimonials" 
              style={{backgroundImage: `url(\"assets/back-plus.svg\")`}}
              >
            <div className="header-and-desc">
              <HeaderBar className="header-bar-sticky" title="Testimonials" color="dark"/>
              <div className="description-testimonials ">
                <div className="description-testimonials-inner">
                    { testimonialspage ? 
                      <BlockContent 
                        blocks={testimonialspage[0].intro} 
                        projectId="cv0gtuav" 
                        dataset="production"
                      />
                    : 
                      <div></div>
                    }
                </div>
              </div>
            </div>
            <div className="featured-panel" >
              <TestimonialsMap/>
            </div>
          </div>

   
      </section>
    </main>
  );
}