import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "client.js";

import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import {plus} from "components/plus";

import { NavHashLink } from 'react-router-hash-link';

import BlockContent from "@sanity/block-content-to-react";

import LookDown from 'icons/look-down.js';

import CarouselDemo from "components/carousel-demo/index.js";
import "./index.scss"

import imageUrlBuilder from "@sanity/image-url";
import { transformFromAstSync } from "@babel/core";
const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}


export default function Home() {
  const [homepage, setHomepage] = useState(null);

  const scrollABit = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -174; 

    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 

  }

  useEffect(() => {
    sanityClient.fetch(`*[_type == "homepage"]{
        header,
        body,
        image,
      }
      `)
      .then((data) => setHomepage(data))
      .catch(console.error);

  }, []);


  return (
    <main className="home-background">
      <section className=" " style={{backgroundImage: `url("assets/back-plus.svg")`}} >
  
        <div className="carousel-wrap" >
          <CarouselDemo className="carousel"/>
        </div>

        <div className="zone-1 fade-in">
          <Row className="zone-1-inner">
            <div className="look-down">
            <NavHashLink   
              to="/#intro" 
              scroll={scrollABit}
              >
                <LookDown/>

              </NavHashLink>
            </div>
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}>
              <h1 id="intro">
                { homepage ? 
                (homepage[0].header)
                : 
                ("We design with your needs in mind.")
                }
              </h1>
            </Col>
            <Row>            
              <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                lg={{ span: 4, offset: 0 }}
                xl={{ span: 4, offset: 0 }}
              >
          

                { homepage ? 
                   <BlockContent 
                    blocks={homepage[0].body} 
                    projectId="cv0gtuav" 
                    dataset="production"
                  />
                : 
                  <div></div>
                }


                <Link 
                  to={"/portfolio"} 
                  key={"portfolio"}
                  className="outlink"
                  >
                  <div >
                    <span className="outlink-text">
                      See All Projects
                    </span>
                    <span> </span>
                    <span className="outlink-arrow">
                      >
                    </span>
                  </div>
                </Link>
                  
              </Col>
              <Col
               xs={{ span: 0, offset: 0 }}
               sm={{ span: 6, offset: 0 }}
               md={{ span: 6, offset: 0 }}
               lg={{ span: 8, offset: 0 }}
               xl={{ span: 8, offset: 0 }}>
                <div className="grid-zone">
                  {plus}
                </div>
                <div className="grid-image" >
                  { homepage ? 
                  <img 
                    src={urlFor(homepage[0].image).url()} 
                    alt={homepage[0].image.alt}
                    key={homepage[0].image._key}
                  />
                  : <img src="assets/_DSC518+8-asset.jpg"/>
                  }
                </div>
              </Col>

            </Row>
          </Row>
        </div>

      
      </section>
    </main>
 

  );
}