import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "client.js";
import BlockContent from "@sanity/block-content-to-react";

import ExternalLink from 'icons/external-link.js';

import { Row, Col } from 'react-simple-flex-grid';

import "react-simple-flex-grid/lib/main.css";
import imageUrlBuilder from "@sanity/image-url";



import "./index.scss";

var moment = require('moment'); // require


const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}

export default function AwardsMap() {
  const [awards, setAwards] = useState(null);

  
  useEffect(() => {
    sanityClient.fetch(`*[_type == "award"] | order(awardDate desc){
      headline,
      project->,
      organization,
      awardDescription,
      link,
      awardDate,
      image,
      tall,
      
    }
    `)
    .then((data) => setAwards(data))
    .catch(console.error);
  }, []);
  



    return (
      
<Row gutter={0} >
      {awards && awards.map((award, index) => {
        //console.log(awards[1].project.slug.current);
        return (
     
        <Col
        
          className="col-visible"
          xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}
          lg={{ span: 12}} xl={{ span: 12 }}
          key={awards[index].headline}
        >
      
              <div className="award-bounds">
                  <div className="award-header">
                    <div className="award-main-text">

                    {awards[index].project ? 
                        <Link 
                        to={"/project/" + awards[index].project.slug.current} 
                        key={awards[index].project.slug.current}
                        >
                          <div className="award-headline underline">
                            {awards[index].headline}
                          </div>
                        </Link>
                        : awards[index].link ? 
                        <a 
                          href={`${awards[index].link}`} 
                          target="_blank"
                          key={awards[index].link}
                          >
                          <div className="award-headline">
                            {awards[index].headline}
                          </div>
                        </a>
                        :
                        <div className="award-headline">
                        {awards[index].headline}
                        </div>
                      }

                      <div className="award-org">
                        {awards[index].organization}
       
                      </div>
                      <div className="award-date">
                        {moment(awards[index].awardDate).format('MMM YYYY')}
                      </div>
          
                      
                     
                      
                    </div>
                    {
                      awards[index].image ? (
                        awards[index].project ? (
                          <Link
                            to={"/project/" + awards[index].project.slug.current}
                            key={awards[index].project.slug.current}
                          >
                            <div className={`award-image-box award-image-box-hover ${awards[index].tall ? 'tall-box' : ''}`}>
                              <div className="see-project">
                                {awards[index].project.title}
                              </div>
                              <img
                                src={urlFor(awards[index].image).url()}
                                alt={awards[index].image.alt}
                                key={index}
                                className="award-image award-image-hover"
                              />
                            </div>
                          </Link>
                        ) : awards[index].link ? (
                          <a
                            href={`${awards[index].link}`}
                            target="_blank"
                            key={awards[index].link}
                          >
                            <div className={`award-image-box ${awards[index].tall ? 'tall-box' : ''}`}>
                              <div className="see-project">
                                <ExternalLink/>
                              </div>
                              <img
                                src={urlFor(awards[index].image).url()}
                                alt={awards[index].image.alt}
                                key={index}
                                className="award-image award-image-hover"
                              />
                            </div>
                          </a>
                        ) : (
                          <div className={`award-image-box ${awards[index].tall ? 'tall-box' : ''}`}>
                            <img
                              src={urlFor(awards[index].image).url()}
                              alt={awards[index].image.alt}
                              key={index}
                              className="award-image"
                            />
                          </div>
                        )
                      ) : (
                        <div></div>
                      )
                    }
                  </div>
                  { awards[index].awardDescription ? 

                  
                  <div className="award-desc">
                    <div></div>
                    <BlockContent
                        blocks={awards[index].awardDescription}
                        projectId={sanityClient.clientConfig.projectId}
                        dataset={sanityClient.clientConfig.dataset}
                        />
                  </div>
                  : 
                  <div></div>
                  }
              </div>
        </Col>
      )})}
    </Row>

  );
  
}