import React, {useEffect, useState} from 'react';
import sanityClient from "client.js";
import { Link } from "react-router-dom";
import { Header } from "components/header-a";
import imageUrlBuilder from "@sanity/image-url";
import './index.scss';

import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";


const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(()=> {
    sanityClient.fetch(
      //groc query
      //find all(*) that have a type equal to 'post'
      `*[_type == "post"]{
        title,
        slug,
        mainImage{
          asset->{
            _id,
            url
          }
        }
      }`
    )
    .then((data) => setAllPosts(data))
    .catch(console.error);
  }, []);

  return (
    <div>
      <Header/>
      <Row gutter={18}>
        <Col span={1} className="gridbox-a"/>
        <Col span={1} className="gridbox-b"/>
        <Col span={1} className="gridbox-a"/>
        <Col span={1} className="gridbox-b"/>
        <Col span={1} className="gridbox-a"/>
        <Col span={1} className="gridbox-b"/>
        <Col span={1} className="gridbox-a"/>
        <Col span={1} className="gridbox-b"/>
        <Col span={1} className="gridbox-a"/>
        <Col span={1} className="gridbox-b"/>
        <Col span={1} className="gridbox-a"/>
        <Col span={1} className="gridbox-b"/>
      </Row>
      <Row>
        <Col className="gridbox-a" xs={2} sm={4} md={6} lg={8} xl={10}>all the break points</Col>
      </Row>
      <h2>Home Page</h2>
      <h3>subtitle</h3>
      <div>
        {allPostsData &&
          allPostsData.map((post, index) => (
            <Link to={'/' + post.slug.current} key={post.slug.current}>
              <span key={index}>
                <span>
                  <h2>{post.title}</h2>
                </span>

                <img 
                  className="sanity-image"
                  //src={post.mainImage.asset.url} 
                  src={urlFor(post.mainImage.asset.url).url()} 
                  alt="main image"
                />
                
                
              </span>
            </Link>
          ))}
      </div>
    </div>
  );
}