import React from 'react';

function LookDown(props) {
  return (
    <svg
      width={38}
      height={24}
      viewBox="0 0 38 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Scroll Down"}</title>
      <path
        d="M3 3l16 16L35 3"
        stroke="#B5D470"
        strokeWidth={6}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default LookDown
