import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import {plus} from "components/plus";
import "./index.scss";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}

export default function SingleProject() {
  const [singleProject, setSingleProject ] = useState(null);
  const { slug } = useParams();

  const [mainGalleryImageIndex, setMainGalleryImageIndex] = useState(0);

  const [readMore,setReadMore]=useState(false);    
  const linkName=readMore?'READ LESS – ':'READ MORE ＋ '

  useEffect(() => {
    sanityClient.fetch(`*[slug.current == "${slug}"]{
      title,
      _id,
      slug,
      location,
      imagesGallery,
      descriptionShort,
      descriptionContinued,
      projectType,
      tags,
      
    }`)
    .then(data => {
          // data[0].mainImage._key = data[0].mainImage.asset._id
          // data[0].imagesGallery.unshift(data[0].mainImage)
          setSingleProject(data[0])
        }
    )
    .catch(console.error);
  }, [slug]);
  
  // loading
  if (!singleProject) return (
    <main className="project-page-back">
    <section className="single-project-article">
      <div className="grid-zone-project" style={{backgroundImage: `url("assets/+-crossover.gif")`, opacity: 0.15, minHeight: 740}}> 
      </div>
    </section>
  </main>

  );
  
  
  else 

  return (
    <main className="project-page-back" >
      <article className="single-project-article fade-in">
        <div className="single-project-top-clipper"></div>
        <div className="single-project-banner">
          <img 
            // src={urlFor(singleProject.mainImage).quality(100).width(1200).height(430).url()}
            // alt={singleProject.mainImage.alt}
            // key={singleProject.mainImage.asset._id}
            src={urlFor(singleProject.imagesGallery[0]).quality(100).width(1200).height(430).url()}
            alt={singleProject.imagesGallery[0].alt}
            key={singleProject.imagesGallery[0].asset._id}
            className="single-project-main-image"
          />
        </div>

        <div className="grid-zone-project">
          {plus}
        </div>

        <div className="header-and-gallery">
          <header className="single-project-header">

            <div className="breadcrumbs">
              <Link 
                to={"/portfolio"} 
                key={'portfolio'}
              >
                <span> </span>
                <div className="breadcrumb">
                  Portfolio 
                </div>
                <span> </span>
              </Link>
              {'>'}
              <Link 
                to={`/category/${singleProject.projectType}`} 
                key={'project-type'}
              >
                <span> </span>
                <div className="breadcrumb">
                  {singleProject.projectType}
                </div>
                <span> </span>
              </Link>
              {'>'}
                    
          
            </div>

            <div className="nub"></div>

            <h1 className="title">
              {singleProject.title}
            </h1>
            <h2 className="location">
              {singleProject.location}
            </h2>
  
            <div className="description-wrap">
              <div className="description" >
                <div className={readMore ? 'description-inner read-more-height':'description-inner'}>
                  <div  className="single-project-description-short">
                      <BlockContent 
                        blocks={singleProject.descriptionShort} 
                        projectId="cv0gtuav" 
                        dataset="production"
                      />
                  </div>

                  { singleProject.descriptionContinued && (
                    <div className="description-continued">

                    { readMore && 
                      <div  className='single-project-description-continued'>
                          <BlockContent 
                            blocks={singleProject.descriptionContinued} 
                            projectId="cv0gtuav" 
                            dataset="production"
                          />
                        </div>
                              
                      
                    }
          
                      <div className={readMore ? 'read-more-box disappear':'read-more-box'}>
                        <div className='read-more-link' onClick={()=>{setReadMore(!readMore)}}>
                          <span>{linkName}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div> 
                <div className={readMore ? 'description-overlay':'description-overlay disappear'} ></div> 
              </div>
                
            </div> 
            <div className={readMore ? 'read-more-box disappear-below-996':'read-more-box disappear disappear-below-996'}>
              <div className="read-more-link" onClick={()=>{setReadMore(!readMore)}}>
                <span>{linkName}</span>
              </div>
            </div>
          </header>

          { singleProject.imagesGallery.length > 1 ? (
            <div >
         
              <div className="gallery">
                <div className="gallery-inner">
                  <div className="MainGalleryImage">
                      <img 
                        src={urlFor(singleProject.imagesGallery[mainGalleryImageIndex]).url()} 
                        //src={urlFor(singleProject.imagesGallery[mainGalleryImageIndex.metadata.lqip]).url()} 
                        alt={singleProject.imagesGallery[mainGalleryImageIndex].alt}
                      
                        key={singleProject.imagesGallery[mainGalleryImageIndex]._key}
                      
                      />
                  </div>
                  <div className="thumbs">
                    {singleProject.imagesGallery.map((project, i) => (
                        <div className="project-page-image-gallery-img" key={singleProject.imagesGallery[i]._key}>
                          <img 
                            onClick={() => setMainGalleryImageIndex(i)}
                            src={urlFor(singleProject.imagesGallery[i])
                              .width(1200)
                              // .height(1200)
                              .url()} 
                            alt={singleProject.imagesGallery[i].alt}
                            key={singleProject.imagesGallery[i]._key}
                          />
                        </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div >
          
              <div className="gallery">
                <div className="gallery-inner">
                  <div className="MainGalleryImage-SingleException" >
                      <img 
                        src={urlFor(singleProject.imagesGallery[0]).url()} 
                        alt={singleProject.imagesGallery[0].alt}
                        key={singleProject.imagesGallery[0]._key}
                      />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

      </article>
    </main>
  );
}

