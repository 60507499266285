import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AllPosts from "./components/allposts-a";
import { OnePost } from "./components/singlepost";
import Home from "./pages/home";
import About from "./pages/about";
import News from "./pages/news";
import Contact from "./pages/contact";
import Portfolio from "./pages/portfolio";
import SingleProject from "./pages/single-project";
import SinglePerson from "./pages/single-person";
import NavBar from "./components/navbar";
import Footer from "./components/footer";
import ScrollToTop from "./components/scroll-to-top";
import ProjectType from "./pages/project-type";
import NotFound from "./pages/not-found";

import useScrollToHash from "./hooks/useScrollToHash";

import "./App.css";
import Testimonials from "pages/testimonials";


function App() {
  return (
    <div>
            <div className="the-border-5"></div>
      <div className="the-border-4"></div>
      <div className="the-border-3"></div>
      <div className="the-border-2"></div>
      <div className="the-border"></div>
      <BrowserRouter>
        <ScrollToTop />
      <div className="the-things">
        <NavBar />

        <div>
          <Switch>
            {/* <Route component={AllPosts} path="/" exact /> */}
            {/* <Route component={OnePost} path="/:slug" /> */}



            <Route component={Home} exact path="/" />
            <Route component={Portfolio} path="/portfolio" />
            <Route component={Testimonials} path="/testimonials" />


            <Route component={About} path="/about" />
            <Route component={News} path="/news" />
            <Route component={Contact} path="/contact" />
            <Route component={SingleProject} path="/project/:slug" />
            <Route component={SinglePerson} path="/people/:slug" />

            {/* <Route component={ProjectType} path="/:slug" />  */}
            {/* add type in front of URL to solve problem. and then fix all cascading issues with links.  */}
            <Route component={ProjectType} path="/category/:slug" /> 

{/* REDIRECTS */}

            <Route exact path="/index.html">
               <Redirect to="/" />
            </Route>

            <Route exact path="/index">
               <Redirect to="/" />
            </Route>

            <Route exact path="/home.html">
               <Redirect to="/" />
            </Route>

            <Route exact path="/home">
               <Redirect to="/" />
            </Route>

            <Route exact path="/people">
               <Redirect to="/about#leadership" />
            </Route>

            <Route exact path="/news.html">
               <Redirect to="/news" />
            </Route>

            <Route exact path="/news1.html">
               <Redirect to="/news#awards" />
            </Route>

            <Route exact path="/news2.html">
               <Redirect to="/news#publications" />
            </Route>


            <Route exact path="/news3.html">
               <Redirect to="/news#press-releases" />
            </Route>

            <Route exact path="/aboutus_1.html">
               <Redirect to="/about" />
            </Route>

            <Route exact path="/aboutus_2.html">
               <Redirect to="/about#approach" />
            </Route>

            <Route exact path="/aboutus_3.html">
               <Redirect to="/about#leadership" />
            </Route>

            <Route exact path="/aboutus_4.html">
               <Redirect to="/about#leadership" />
            </Route>

            <Route exact path="/green_1.html">
               <Redirect to="/about" />
            </Route>

            <Route exact path="/portfolio_1.html">
               <Redirect to="/portfolio" />
            </Route>

            <Route exact path="/portfolio_2.html">
               <Redirect to="/category/libraries" />
            </Route>

            <Route exact path="/portfolio_3.html">
               <Redirect to="/category/government" />
            </Route>

            <Route exact path="/portfolio_4.html">
               <Redirect to="/category/corporate" />
            </Route>

            <Route exact path="/portfolio_5.html">
               <Redirect to="/category/religious" />
            </Route>

            <Route exact path="/portfolio_6.html">
               <Redirect to="/category/residential" />
            </Route>

            <Route exact path="/portfolio_7.html">
               <Redirect to="/category/educational" />
            </Route>

            <Route exact path="/clients_1.html">
                <Redirect to="/about#testimonials" />
            </Route>

            <Route exact path="/contact_1.html">
                <Redirect to="/contact" />
            </Route>


{/* FEATURED */}

            <Route exact path="/portfolio_7b.html">
                <Redirect to="/project/berkeley-college-master-plan" />
            </Route>

            <Route exact path="/portfolio_4a.html">
                <Redirect to="/project/daiichi-sankyo-pharma-development" />
            </Route>

            <Route exact path="/portfolio_7f.html">
                <Redirect to="/project/st-thomas-aquinas-college-campus-master-plan" />
            </Route>

            <Route exact path="/portfolio_6d.html">
                <Redirect to="/project/sol-vista-apartments" />
            </Route>

            <Route exact path="/portfolio_5a.html">
                <Redirect to="/project/zichron-mordechai-synagogue" />
            </Route>

{/* LIBRARIES */}
            <Route exact path="/portfolio_2a.html">
                <Redirect to="/project/monroe-township-public-library" />
            </Route>

            <Route exact path="/portfolio_2b.html">
                <Redirect to="/project/clark-public-library" />
            </Route>

            <Route exact path="/portfolio_2c.html">
                <Redirect to="/project/glen-ridge-public-library" />
            </Route>

            <Route exact path="/portfolio_2d.html">
                <Redirect to="/project/south-river-public-library" />
            </Route>

            <Route exact path="/portfolio_2e.html">
                <Redirect to="/project/holmdel-library-at-bell-works" />
            </Route>

            <Route exact path="/portfolio_2f.html">
                <Redirect to="/project/oakland-public-library" />
            </Route>

            <Route exact path="/portfolio_2g.html">
                <Redirect to="/project/clark-public-library" />
            </Route>

            <Route exact path="/portfolio_2h.html">
                <Redirect to="/project/seton-hall-university-rodino-law-library-conceptual-design-library" />
            </Route>

            <Route exact path="/portfolio_2i.html">
                <Redirect to="/project/north-brunswick-public-library" />
            </Route>

            
{/* GOVERNMENT */}

            <Route exact path="/portfolio_3a.html">
                <Redirect to="/project/hasbrouck-heights-safety-building" />
            </Route>

            <Route exact path="/portfolio_3b.html">
                <Redirect to="/project/hasbrouck-heights-municipal-building" />
            </Route>

            <Route exact path="/portfolio_3c.html">
                <Redirect to="/404" />
            </Route>

            <Route exact path="/portfolio_3d.html">
                <Redirect to="/project/tenafly-police-headquarters" />
            </Route>

            <Route exact path="/portfolio_3e.html">
                <Redirect to="/project/davis-johnson-park" />
            </Route>

            <Route exact path="/portfolio_3f.html">
                <Redirect to="/project/river-vale-community-center" />
            </Route>

            <Route exact path="/portfolio_3g.html">
                <Redirect to="/project/edgewater-borough-hall" />
            </Route>

            <Route exact path="/portfolio_3h.html">
                <Redirect to="/project/edgewater-fire-department" />
            </Route>

            <Route exact path="/portfolio_3i.html">
                <Redirect to="/project/berkeley-heights-municipal-complex" />
            </Route>

{/* COMMERCIAL */}
{/* Getting lazy and have to generalize here */}

            <Route exact path="/portfolio_4a.html">
                <Redirect to="/category/commercial" />
            </Route>

            <Route exact path="/portfolio_4b.html">
                <Redirect to="/category/commercial" />
            </Route>

            <Route exact path="/portfolio_4c.html">
                <Redirect to="/category/commercial" />
            </Route>

            <Route exact path="/portfolio_4d.html">
                <Redirect to="/category/commercial" />
            </Route>

            <Route exact path="/portfolio_4e.html">
                <Redirect to="/category/commercial" />
            </Route>

            <Route exact path="/portfolio_4f.html">
                <Redirect to="/category/commercial" />
            </Route>

            <Route exact path="/portfolio_4g.html">
                <Redirect to="/category/commercial" />
            </Route>

            <Route exact path="/portfolio_4h.html">
                <Redirect to="/category/commercial" />
            </Route>

            <Route exact path="/portfolio_4i.html">
                <Redirect to="/category/commercial" />
            </Route>

{/* RELIGIOUS */}

            <Route exact path="/portfolio_5a.html">
                <Redirect to="/category/religious" />
            </Route>

            <Route exact path="/portfolio_5b.html">
                <Redirect to="/category/religious" />
            </Route>

            <Route exact path="/portfolio_5c.html">
                <Redirect to="/category/religious" />
            </Route>

            <Route exact path="/portfolio_5d.html">
                <Redirect to="/category/religious" />
            </Route>

            <Route exact path="/portfolio_5e.html">
                <Redirect to="/category/religious" />
            </Route>

            <Route exact path="/portfolio_5f.html">
                <Redirect to="/category/religious" />
            </Route>

            <Route exact path="/portfolio_5g.html">
                <Redirect to="/category/religious" />
            </Route>

            <Route exact path="/portfolio_5h.html">
                <Redirect to="/category/religious" />
            </Route>

            <Route exact path="/portfolio_5i.html">
                <Redirect to="/category/religious" />
            </Route>

{/* RESIDENTIAL */}

            <Route exact path="/portfolio_6a.html">
                <Redirect to="/category/residential" />
            </Route>

            <Route exact path="/portfolio_6b.html">
                <Redirect to="/category/residential" />
            </Route>

            <Route exact path="/portfolio_6c.html">
                <Redirect to="/category/residential" />
            </Route>

            <Route exact path="/portfolio_6d.html">
                <Redirect to="/category/residential" />
            </Route>

            <Route exact path="/portfolio_6e.html">
                <Redirect to="/category/residential" />
            </Route>

            <Route exact path="/portfolio_6f.html">
                <Redirect to="/category/residential" />
            </Route>

            <Route exact path="/portfolio_6g.html">
                <Redirect to="/category/residential" />
            </Route>

            <Route exact path="/portfolio_6h.html">
                <Redirect to="/category/residential" />
            </Route>

            <Route exact path="/portfolio_6i.html">
                <Redirect to="/category/residential" />
            </Route>
          
{/* EDUCATIONAL */}

            <Route exact path="/portfolio_7a.html">
                <Redirect to="/category/educational" />
            </Route>

            <Route exact path="/portfolio_7b.html">
                <Redirect to="/category/educational" />
            </Route>

            <Route exact path="/portfolio_7c.html">
                <Redirect to="/category/educational" />
            </Route>

            <Route exact path="/portfolio_7d.html">
                <Redirect to="/category/educational" />
            </Route>

            <Route exact path="/portfolio_7e.html">
                <Redirect to="/category/educational" />
            </Route>

            <Route exact path="/portfolio_7f.html">
                <Redirect to="/category/educational" />
            </Route>

            <Route exact path="/portfolio_7g.html">
                <Redirect to="/category/educational" />
            </Route>

            <Route exact path="/portfolio_7h.html">
                <Redirect to="/category/educational" />
            </Route>

            <Route exact path="/portfolio_7i.html">
                <Redirect to="/category/educational" />
            </Route>       
            
            {/* <Route component={NotFound} path="*" /> */}

            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />

          </Switch>
        </div>
        <Footer id="footer"/>
      </div>
      </BrowserRouter>

    </div>


  );
}

export default App;
