import { Row, Col } from 'react-simple-flex-grid';

import "react-simple-flex-grid/lib/main.css";
import "./index.scss";

export default function HeaderBar({className, title, color, id}) {

return (
<>


  <div className={`header-bar-class ${className}`} id={`${id}`}>
    <div className= {`cool 
    
      ${color == 'dark' ? ('dark') : ''}  
      ${color == 'medium' ? ('medium') : ''}  
      ${color == 'black' ? ('black') : ''}  
      ${color == 'maroon' ? ('maroon') : ''}  
      ${color == 'bluer' ? ('bluer') : ''}  
      ${color == 'greener' ? ('greener') : ''}  
    `}>

    </div>
    <div >
      <div className={`header-text 
      
      ${color == 'dark' ? ('dark') : ''}  
      ${color == 'medium' ? ('medium') : ''}  
      ${color == 'black' ? ('black') : ''}  
      ${color == 'maroon' ? ('maroon') : ''}  
      ${color == 'bluer' ? ('bluer') : ''}  
      ${color == 'greener' ? ('greener') : ''}  
      `}
      >
        <h1>
          {title}
        </h1>
      </div>

    </div>

  </div>

</>
)};