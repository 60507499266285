import React from "react"
import "./index.scss"
import { NavLink } from "react-router-dom";

import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";


export default function Footer() {
  return (
    <footer className="footer">
      <Row >
        <Col className="footer-title"
        xs={{ span: 12, offset: 0 }}
        sm={{ span: 3, offset: 0 }}
        md={{ span: 3, offset: 0 }}
        lg={{ span: 2, offset: 0 }}
        xl={{ span: 2, offset: 0 }}
        >
          <span className="nowrap">Arcari + Iovino Architects</span>
        </Col>
        <Col className="wide-col top-contact"
          xs={{ span: 4, offset: 0 }}
          sm={{ span: 1, offset: 1 }}
          md={{ span: 1, offset: 0 }}
          lg={{ span: 1, offset: 1 }}
          xl={{ span: 1, offset: 0 }}
        
        >
          <NavLink 
            to="/contact" 
            activeClassName="nav-active"
          >
            Contact
          </NavLink>
        </Col>
        <Col 
          xs={8} 
          sm={{span: 3, offset: 1}} 
          md={{span: 2, offset: 0}} 
          lg={{span: 2, offset: 0}} 
          xl={{span: 2, offset: 0}}
          className="contact-items wide-col">
            <div>
              <span>201.641.0600</span>

            </div>
          <div>
            <span>One Katherine Street<br/>
            Little Ferry, NJ 07643</span>
          </div>
          <div>
            <a href="mailto:aiovino@aiarchs.com">Email us</a>
          </div>
          <br/>
        </Col>
        <Col
          sm={{span: 4, offset: 0}} 
          md={{span: 4, offset: 1}} 
          lg={{span: 4, offset: 1}} 
          xl={{span: 4, offset: 2}}
          className="wide-col"
          >
           <span>
            <span className="nowrap">Arcari + Iovino Architects</span> was named the 2011 
            “Firm of the Year” by the<span> </span>
            <span className="nowrap">American Institute</span> of Architects - <span className="nowrap">NJ.</span>
          </span>
          <div className="other-logos">
            <img className="other-logo aia" src="/assets/AIA-Member_RED-BLACK_RGB-01.png"/>
            <img className="other-logo" src="/assets/Eagle+AIA ALNNJ -2014.png"/>
            <img className="other-logo" src="/assets/Leed-Logo.png"/>
            <img className="other-logo" src="/assets/usgbc-membership-logo.png"/>
          </div>
        </Col>
      </Row>
      <Row className="mobile-col">
        <Col className="mobile-col-sm"
          xs={{ span: 2, offset: 0 }}
          sm={{ span: 2, offset: 0 }}
          md={{ span: 1, offset: 0 }}
          lg={{ span: 1, offset: 0 }}
          xl={{ span: 1, offset: 0 }}
        
        >
          <NavLink 
            to="/contact" 
            activeClassName="nav-active"
          >
            Contact
          </NavLink>
        </Col>
        <Col xs={{ span: 8, offset: 0 }} sm={3} md={2} lg={2} xl={2}
          className="contact-items">
            <div>
              <span>201.641.0600</span>

            </div>
          <div>
            <span>One Katherine Street<br/>
            Little Ferry, NJ 07643</span>
          </div>
          <div>
            <a href="mailto:aiovino@aiarchs.com">Email us</a>
          </div>
          
         
          <br/>
          
        </Col>

        {/* <Col xs={{ span: 12, offset: 0 }} sm={{ span: 10, offset: 1 }} md={2} lg={2} xl={2}
        className="contact-items">
 <img className="aia-logo" src="assets/AIA-Member_RED-BLACK_RGB-01.png"/>
           <img className="aia-logo other-logo" src="/assets/Eagle+AIA ALNNJ -2014.png"/>
            <img className="aia-logo other-logo" src="/assets/Leed-Logo.png"/>
            <img className="aia-logo other-logo" src="assets/usgbc-membership-logo.png"/>

        </Col> */}
        <Col
          xs={{span: 4, offset: 0}} 
          sm={{span: 5, offset: 1}} 
        
          className="mobile-2011" 
          >
           <span>
            <span className="nowrap">Arcari + Iovino Architects</span> was named the 2011 
            “Firm of the Year” by the<span> </span>
            <span className="nowrap">American Institute</span> of Architects - <span className="nowrap">NJ.</span>
          </span>
          <div className="other-logos">
            <img className="other-logo aia" src="../assets/AIA-Member_RED-BLACK_RGB-01.png"/>
            <img className="other-logo" src="../assets/Eagle+AIA ALNNJ -2014.png"/>
            <img className="other-logo" src="../assets/Leed-Logo.png"/>
            <img className="other-logo" src="../assets/usgbc-membership-logo.png"/>


            
          </div>
        </Col>
      </Row>
    </footer>
  );
}