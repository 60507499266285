import React, {useEffect, useState} from "react"
import "./index.scss";

import HeaderBar from "components/header-bar";
import {AddressMap} from "components/address-map";
// import ConstantContact from "components/constant-contact";
import sanityClient from "client.js";

import BlockContent from "@sanity/block-content-to-react";

import { useMountEffect } from "hooks/useMountEffect";

export default function Contact() {
  // function refreshPage() {
  //   window.location.reload(false);
  // }
  
  // useMountEffect(refreshPage);

  const [contactpage, setContactpage] = useState(null);


  useEffect(() => {
    sanityClient.fetch(`*[_type == "contactpage"]{
        intro,
      }
      `)
      .then((data) => setContactpage(data))
      .catch(console.error);

  }, []);

  return (
    <main className="contact-page-back">
      <section className="portfolio-section">



          <div className="portfolio-zone-1" id="main" style={{backgroundImage: `url(\"assets/back-plus.svg\")`}}>
            <div className="header-and-desc contact-zone">
              <HeaderBar className="header-bar-sticky" title="Contact" color="dark"/>
              <div className="description-about">
                <div className="description-contact-inner smaller-column">
                  <div className="contact-block">
                    { contactpage ? 
                      <BlockContent 
                        blocks={contactpage[0].intro} 
                        projectId="cv0gtuav" 
                        dataset="production"
                      />
                    : 
                      <div></div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="constant-contact-block fade-in-delay-more">
                <ConstantContact/>
            </div> */}
            <div className="featured-panel-gform fade-in-delay-more">
                <iframe  src="https://docs.google.com/forms/d/e/1FAIpQLSeAuw3u3H-vXpq7Gfmd-XryyiMFbLMV5_uDRR4raUUaNaRASQ/viewform?embedded=true" width="400" height="512" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
           
            <div className="featured-panel-contact fade-in-delay-more">
              <AddressMap/>
            </div>
          </div>

            
      </section>
    </main>
  );
}