import React, {useEffect, useState} from "react";
import { useParams, Link } from "react-router-dom";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import './singlepost.scss';
import { Header } from "components/header-a"

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}


export const OnePost = ({}) => {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();
  
  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title, 
          slug, 
          mainImage{
            asset->{
              _id,
              url
            }
          },
          body,
          "name": author->name,
          "authorImage": author->image
        }`,
          { slug }
        )
        .then((data) => setPostData(data[0]))
        .catch(console.error);
  }, [slug]);

  //loading page
  if(!postData) return <div><Header/>Loading...</div>

  return(
    <div>
      <Header/>
      <div id="fade-test" className={["one-post", "fade-in"].join(' ')}>
        <Link to={'/'} key={'home'}>
          <span>go home</span>
        </Link>
        <div>
          <h2>{postData.title}</h2>
          <div>
            <img 
              src={urlFor(postData.authorImage).width(100).url()}
              alt="author is Joe"
            />
            <h4>{postData.name}</h4>
          </div>
        </div>
        <img 
          src={urlFor(postData.mainImage).width(200).url()} 
          alt="text" 
        />
        <div>
          <BlockContent
            blocks={postData.body}
            projectId={sanityClient.clientConfig.projectId}
            dataset={sanityClient.clientConfig.dataset}
          />
        </div>
      </div>
    </div>
  );
}