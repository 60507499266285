import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "client.js";
import BlockContent from "@sanity/block-content-to-react";

import ExternalLink from 'icons/external-link.js';

import { Row, Col } from 'react-simple-flex-grid';

import "react-simple-flex-grid/lib/main.css";
import imageUrlBuilder from "@sanity/image-url";




import "./index.scss";

var moment = require('moment'); // require


const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}

export default function PublicationsMap() {
  const [publications, setPublications] = useState(null);

  
  useEffect(() => {
    sanityClient.fetch(`*[_type == "publication"] | order(publicationDate desc){
      headline,
      slug,
      publicationDate,
      pressFile,
      link,
      "fileLink": pressFile.asset->url,
      publicationDescription,
      image,
      tall,

    }
    `)
    .then((data) => setPublications(data))
    .catch(console.error);
  }, []);
  



    return (
      
<Row gutter={0} >
      {publications && publications.map((publication, index) => {
     
        return (
     
        <Col
        
          className="col-visible"
          xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}
          lg={{ span: 12}} xl={{ span: 12 }}
          key={publications[index].headline}
        >
      
              <div className="award-bounds">
                  <div className="award-header">
                    <div className="award-main-text">

                    { publications[index].link ?

                      <a 
                      //href={`${publications[index].fileLink}?dl=${publications[index].slug.current}.pdf`} 
                      href={`${publications[index].link}`} 
                      target="_blank"
                      key={publications[index].slug}
                      >
                        <div className="award-headline underline">
                          {publications[index].headline}
                        </div>
                      </a>
                    :
                    
                    publications[index].fileLink ? (
                      <a 
                      //href={`${publications[index].fileLink}?dl=${publications[index].slug.current}.pdf`} 
                      href={`${publications[index].fileLink}`} 
                      target="_blank"
                      key={publications[index].slug}
                      >
                        <div className="award-headline underline">
                          {publications[index].headline}
                        </div>
                      </a>
                    ) : (
                      <div className="award-headline">
                        {publications[index].headline}
                      </div>
                    )}


                      <div className="award-org">
                   
       
                      </div>
                      <div className="award-date">
                        {moment(publications[index].publicationDate).format('MMM YYYY')}
                      </div>
          
                      
                      
                      
                    </div>
                    { publications[index].image ? (

                      publications[index].fileLink ? 
                      <a 
                        //href={`${publications[index].fileLink}?dl=${publications[index].slug.current}.pdf`} 
                        href={`${publications[index].fileLink}`} 
                        target="_blank"
                        key={publications[index].slug}
                        >

                        <div className="award-image-box award-image-box-hover">
                          <div className="see-project">
                            
                            <ExternalLink/>
                            
                          </div>
                          <img 
                              src={urlFor(publications[index].image).url()}
                              alt={publications[index].image.alt}
                              key={index}
                              className="award-image award-image-hover"
                            />
                        </div>

                      </a>

                      : publications[index].link ?

                      <a 
                        //href={`${publications[index].fileLink}?dl=${publications[index].slug.current}.pdf`} 
                        href={`${publications[index].link}`} 
                        target="_blank"
                        key={publications[index].slug}
                        >

                        <div className="award-image-box award-image-box-hover">
                          <div className="see-project">
                            
                            <ExternalLink/>
                            
                          </div>
                          <img 
                              src={urlFor(publications[index].image).url()}
                              alt={publications[index].image.alt}
                              key={index}
                              className="award-image award-image-hover"
                            />
                        </div>

                      </a>

                      :

                      <div className="award-image-box">
                      <img 
                          src={urlFor(publications[index].image).url()}
                          alt={publications[index].image.alt}
                          key={index}
                          className="award-image"
                        />
                      </div>

                    )
                    
                    : 
                      <div></div>
                    }
                  </div>
                  { publications[index].publicationDescription ? 

                  
                  <div className="award-desc">
                    <div></div>
                    <BlockContent
                        blocks={publications[index].publicationDescription}
                        projectId={sanityClient.clientConfig.projectId}
                        dataset={sanityClient.clientConfig.dataset}
                        />
                  </div>
                  : 
                  <div></div>
                  }
              </div>
        </Col>
      )})}
    </Row>

  );
  
}