import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";

import { NavHashLink, HashLink } from 'react-router-hash-link';

import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";

import "./index.scss";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const positionMap = {
  founder: 'Founder',
  seniorpartner: 'Senior Partner',
  partner: 'Partner',
  juniorpartner: 'Junior Partner',
};


export default function SinglePerson() {
  const [singlePerson, setSinglePerson] = useState(null);
  const { slug } = useParams();


  const [readMore,setReadMore]=useState(false);    
  const linkName=readMore?'READ LESS – ':'READ MORE ＋ '



  function delayScrollWithOffset(el) {
    setTimeout(
      function(){ 
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -94; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
      },
      300);
  }



  useEffect(() => {
    sanityClient.fetch(`*[slug.current == "${slug}"]{
      title,
      _id,
      slug,
      name,
      accreditations,
      image,
      bio,
      bioShort,
      bioContinued,
      position,
      projects[]-> {
        title,
        location,
        imagesGallery,
        slug,
        // add more fields as needed
      }
    }`)
    .then(data => {
      setSinglePerson(data[0]);
    })
    .catch(console.error);
  }, [slug]);

  if (!singlePerson) {
    return (
      <main className="person-page-back">
        <section className="project-type-section fade-in">
          <div className="project-type-zone-1" style={{backgroundImage: `url("../assets/+-crossover.gif")`, opacity: 0.15, minHeight: 740}}> 
          </div>
        </section>
      </main>
    );
  }

  return (
    <main className="person-page-back" >
      <article className="single-person-article fade-in">
        


          <div className="profile-wrap-0">

            <Row className="profile-wrap-A grid-texture">

              <Col
                className="col-visible carousel-wrap"
                xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}
                lg={{ span: 12 }} xl={{ span: 12 }}>
              <Row className="profile-wrap-B ">


                <Col >
                  <Row >
                    <div className="breadcrumbs people-text-color">
                      <Link 
                        to={"/about"} 
                        key={'about'}
                        >
                        <span> </span>
                        <div className="breadcrumb people-text-color">
                          About 
                        </div>
                        <span> </span>
                      </Link>
                        {'>'}

                        <NavHashLink 
                        
                        to="/about#leadership" 
                        scroll={delayScrollWithOffset}
                        activeClassName="nav-active mobile-option-active dropdown-item-active  "
                        className=""
                      >
                        <span> </span>
                        <div className="breadcrumb people-text-color">
                          Leadership 
                        </div>
                        <span> </span>
                      </NavHashLink>
                      {'>'}
                    </div> 
                  </Row>
                  <Row>
             

                    <Col
                      className="col-visible"
                      xs={{ span: 12 }} sm={{ span: 12 }} md={{ span:6 }}
                      lg={{ span: 6 }} xl={{ span: 6 }}
                    >
   
                      <div className="profile-box-image">
                        
                        <img 
                          src={urlFor(singlePerson.image)}
                          alt={singlePerson.image.alt}
                          key={singlePerson.image.asset._id}
                          className=""
                        />
                        
                      </div>


                    </Col>
                    <Col
                      className="col-visible profile-box-2"
                      xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }}
                      lg={{ span: 6 }} xl={{ span: 6 }}
                    >

    <div className="nub people-nub"></div>
                      <h1 className="people-name">
                        {singlePerson.name}
                      </h1>

                      {singlePerson.accreditations ?  (
                      <h2 className="people-accreditation">
                        {singlePerson.accreditations}
                      </h2>

                      ) : (
                        <div className="people-accreditation"></div>
                      ) }


                      <h2 className="people-position">
                      {positionMap[singlePerson.position]}
                      </h2>

                      <div className="description-wrap">
                    <div className="description people-description" >
                      <div className={readMore ? 'description-inner read-more-height':'description-inner'}>
                        <div  className="single-project-description-short">
                            <BlockContent 
                              blocks={singlePerson.bioShort} 
                              projectId="cv0gtuav" 
                              dataset="production"
                            />
                        </div>

                        { singlePerson.bioContinued && (
                          <div className="description-continued">

                          { readMore && 
                            <div  className='single-project-description-continued'>
                                <BlockContent 
                                  blocks={singlePerson.bioContinued} 
                                  projectId="cv0gtuav" 
                                  dataset="production"
                                />
                              </div>
                                    
                            
                          }
                
                            <div className={readMore ? 'read-more-box disappear':'read-more-box'}>
                              <div className='read-more-link people-text-color' onClick={()=>{setReadMore(!readMore)}}>
                                <span>{linkName}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div> 
                      <div className={readMore ? 'description-overlay people-description-overlay':'description-overlay people-description-overlay disappear'} ></div> 
                    </div>
                      
                      </div> 
                      <div className={readMore ? 'read-more-box disappear-below-996':'read-more-box disappear disappear-below-996'}>
                        <div className="read-more-link people-text-color" onClick={()=>{setReadMore(!readMore)}}>
                          <span>{linkName}</span>
                        </div>
                      </div>


                    </Col>

                  </Row>
                </Col>

                {singlePerson.projects && (
                <Col className="divider"
                 xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}
                 lg={{ span: 12 }} xl={{ span: 12 }}
                 >
                    <div>
                      <h1 className="featured-projects-header">Featured Projects</h1>
                    <div className="featured-projects-wrap-fix">

                      {singlePerson.projects.map(project => (
                        <Col
                          className="col-visible"
                          xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}
                          lg={{ span: 4 }} xl={{ span: 3 }}
                          key={project._id}
                        >
                          <div className="project-card-wrap-wrap">
                            <Link 
                              to={"/project/" + project.slug.current}
                              className="project-card-wrap people-project-card-wrap"
                            >
                              <div className="card-img" key={project._id}>
                                <img 
                                  src={urlFor(project.imagesGallery[0]).url()}
                                  alt={project.imagesGallery[0].alt}
                                  key={project._id}
                                  className="main-image-project-type"
                                />
                              </div>
                              <div className="card-description people-project-card-text">
                                <h3>
                                  {project.title}
                                </h3>
                                <h4>
                                  {project.location}
                                </h4>
                              </div> 
                            </Link>
                          </div>
                        </Col>
                      ))}

                    </div>



                    </div>
                </Col>
                )}



              </Row>
              </Col>


            </Row>
          </div>

      </article>
    </main>
  );
  
}

