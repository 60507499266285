import React from 'react';
import "./index.scss";
 const AddressMap=()=>{
    return (
        <div className="google-map-code">
          <iframe className="extralarge-map" title="aiarchs-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1905.9465241047806!2d-74.04089158696715!3d40.848251979215874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f9d1ebd27cc9%3A0xcef1117186de57ac!2sArcari%20Iovino%20Architects!5e1!3m2!1sen!2sus!4v1613366541853!5m2!1sen!2sus" width="468" height="430" frameBorder="0" style={{width:"34vw", border:"18px solid #f3f3f3", borderRadius:"12px"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        
          <iframe className="large-map" title="aiarchs-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1905.9465241047806!2d-74.04089158696715!3d40.848251979215874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f9d1ebd27cc9%3A0xcef1117186de57ac!2sArcari%20Iovino%20Architects!5e1!3m2!1sen!2sus!4v1613366541853!5m2!1sen!2sus" width="648" height="430" frameBorder="0" style={{width:"648px", border:"18px solid #f3f3f3", borderRadius:"12px"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        
          <iframe className="medium-map" title="aiarchs-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1905.9465241047806!2d-74.04089158696715!3d40.848251979215874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f9d1ebd27cc9%3A0xcef1117186de57ac!2sArcari%20Iovino%20Architects!5e1!3m2!1sen!2sus!4v1613366541853!5m2!1sen!2sus" width="520" height="430" frameBorder="0" style={{width:"97vw", border:"18px solid #f3f3f3", borderRadius:"12px"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        
          <iframe className="small-map" title="aiarchs-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1905.9465241047806!2d-74.04089158696715!3d40.848251979215874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f9d1ebd27cc9%3A0xcef1117186de57ac!2sArcari%20Iovino%20Architects!5e1!3m2!1sen!2sus!4v1613366541853!5m2!1sen!2sus" width="300" height="400" frameBorder="0" style={{width:"94vw", border:"18px solid #f3f3f3", borderRadius:"12px"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        
          {/* <div>
              <iframe width="500" height="400" frameborder="0" src="https://www.bing.com/maps/embed?h=400&w=500&cp=40.848286504627694~-74.04072607333313&lvl=18&typ=d&sty=h&src=SHELL&FORM=MBEDV8" scrolling="no">
              </iframe>
              <div style={{whiteSpace: "nowrap", textAlign: "center", width: 500,}}>
                  <a id="largeMapLink" target="_blank" href="https://www.bing.com/maps?cp=40.848286504627694~-74.04072607333313&amp;sty=h&amp;lvl=18&amp;FORM=MBEDLD">View Larger Map</a> &nbsp; | &nbsp;
                  <a id="dirMapLink" target="_blank" href="https://www.bing.com/maps/directions?cp=40.848286504627694~-74.04072607333313&amp;sty=h&amp;lvl=18&amp;rtp=~pos.40.848286504627694_-74.04072607333313____&amp;FORM=MBEDLD">Get Directions</a>
              </div>
          </div> */}
        </div>
    );
 }
 export{AddressMap}