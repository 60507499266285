import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "client.js";

import {plus} from "components/plus";

import LookDown from 'icons/look-down.js';

import { Row, Col } from 'react-simple-flex-grid';

import "react-simple-flex-grid/lib/main.css";

import HeaderBar from "components/header-bar";

import BlockContent from "@sanity/block-content-to-react";

import AwardsMap from "components/awards-map";
import PressReleasesMap from "components/press-releases-map";
import PublicationsMap from "components/publications-map";


import imageUrlBuilder from "@sanity/image-url";
import "./index.scss";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}



export default function News() {
  const [newsData, setNews] = useState(null);
  const [awardspage, setAwardspage] = useState(null);
  const [pressreleasespage, setPressreleasespage] = useState(null);
  const [publicationspage, setPublicationspage] = useState(null);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "project" && featured == true]{
        title,
        location,
        slug,
        imagesGallery,
      }
      `)
      .then((data) => setNews(data))
      .catch(console.error);

  }, []);


  useEffect(() => {
    sanityClient.fetch(`*[_type == "awardspage"]{
        intro,
        banner{
          asset->{
            _id,
            url
          }
        },
      }
      `)
      .then((data) => setAwardspage(data))
      .catch(console.error);

  }, []);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "pressreleasespage"]{
        intro,
        banner{
          asset->{
            _id,
            url
          }
        },
      }
      `)
      .then((data) => setPressreleasespage(data))
      .catch(console.error);

  }, []);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "publicationspage"]{
        intro,
        banner{
          asset->{
            _id,
            url
          }
        },
      }
      `)
      .then((data) => setPublicationspage(data))
      .catch(console.error);

  }, []);



  if (!newsData | !awardspage | !pressreleasespage | !publicationspage) return (
    <div className="news-page-back" > 
     <section className="portfolio-section"
      style={{backgroundImage: `url("assets/back-plus.svg")`}} 
      >
     </section>
  </div>

  );
  

  return (
    <main className="news-page-back">
      <section className="portfolio-section fade-in">



    

     


          <div style={{background: `linear-gradient(90deg, rgba(233,226,224,1) 0%, rgba(244,232,212,1) 100%)`}}>

            <div id="awards" className="banner" >
              { awardspage ? 
                <img 
                  src={urlFor(awardspage[0].banner).url()} 
                  alt="banner"
                />
                : <img src="assets/section-banner.png"/>
                }
            </div>

            <div className="portfolio-zone-1 press-releases-bg-img" >
              {/* <div className="awards-bg-img" style={{backgroundImage: `url('/assets/_DSC5705+8.jpg')`}}></div> */}
              <HeaderBar className="header-bar-sticky awards-header-mobile" title="Awards" color="maroon" id="header" />
              <div className="header-and-desc">
                <HeaderBar className="header-bar-sticky awards-header-desktop" title="Awards" color="maroon" />
                <div className="description-news awards-description ">
                  <div className="description-news-inner dark-text">
                    { awardspage ? 
                      <BlockContent 
                        blocks={awardspage[0].intro} 
                        projectId="cv0gtuav" 
                        dataset="production"
                      />
                    : 
                      <div></div>
                    }
                  </div>
                </div>
              </div>
              <div className="featured-panel-awards" >
                <AwardsMap/>
              </div>
            </div>
            
          </div>



          <div style={{background: `linear-gradient(90deg, rgba(224,230,233,1) 0%, rgba(212,221,244,1) 100%)`}}>

            

            <div id="press-releases" className="banner" >
              { awardspage ? 
                <img 
                  src={urlFor(pressreleasespage[0].banner).url()} 
                  alt="banner"
                />
                : <img src="assets/section-banner.png"/>
                }
            </div>

            <div className="portfolio-zone-1 press-releases-bg-img "  >
              {/* <div className="press-releases-bg-img" style={{backgroundImage: `url('/assets/_DSC5705+8.jpg')`}}></div> */}
              <HeaderBar className="header-bar-sticky awards-header-mobile" title="Press Releases" color="bluer" />
              <div className="header-and-desc">
                <HeaderBar className="header-bar-sticky awards-header-desktop" title="Press Releases" color="bluer" />
                <div className="description-news awards-description ">
                  <div className="description-news-inner dark-text">
                    { pressreleasespage ? 
                      <BlockContent 
                        blocks={pressreleasespage[0].intro} 
                        projectId="cv0gtuav" 
                        dataset="production"
                      />
                    : 
                      <div></div>
                    }
                  </div>
                </div>
              </div>
              <div className="featured-panel-awards" >
                <PressReleasesMap/>
              </div>
            </div>

          </div>


        

                                                                                        {/* linear-gradient(270deg, rgba(18,35,94,1) 0%, rgba(85,125,182,1) 100%) */}
          <div style={{background: `linear-gradient(90deg, rgba(244,240,220,1) 0%, rgba(236,240,222,1) 100%)`}}>

            <div id="publications" className="banner" >
              <img  src="assets/section-banner.png" />
            </div>

            <div className="portfolio-zone-1 press-releases-bg-img "  >
              {/* <div className="press-releases-bg-img" style={{backgroundImage: `url('/assets/_DSC5705+8.jpg')`}}></div> */}
              <HeaderBar className="header-bar-sticky awards-header-mobile" title="Publications" color="greener" />
              <div className="header-and-desc">
                <HeaderBar className="header-bar-sticky awards-header-desktop" title="Publications" color="greener" />
                <div className="description-news awards-description ">
                  <div className="description-news-inner dark-text">
                    { publicationspage ? 
                      <BlockContent 
                        blocks={publicationspage[0].intro} 
                        projectId="cv0gtuav" 
                        dataset="production"
                      />
                    : 
                      <div></div>
                    }
                  </div>
                </div>
              </div>
              <div className="featured-panel-awards" >
                <PublicationsMap/>
              </div>
            </div>

          </div>




        
      </section>
    </main>
  );
}