import React from 'react';

function CaretPlus(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"+"}</title>
      <path
        fill="#B5D470"
        fillRule="nonzero"
        d="M9.894 15l.255-.255v-4.34l.255-.256h4.34L15 9.894V8.106l-.255-.255h-4.34l-.256-.255v-4.34L9.894 3H8.106l-.255.255v4.34l-.255.256h-4.34L3 8.106v1.788l.255.255h4.34l.256.255v4.34l.255.256z"
      />
    </svg>
  )
}

export default CaretPlus
