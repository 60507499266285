import React from "react";
import './index.scss'

export function CarouselProgress({progress, total}){

  var percentage = (progress/total);
  return(
    <div  
      style={{width: `calc(100% * ${percentage})`}}
      className="progress-bar">
    </div>
  );
}