import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "client.js";

import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import {plus} from "components/plus";

import { NavHashLink } from 'react-router-hash-link';

import BlockContent from "@sanity/block-content-to-react";

import "./index.scss"




export default function NotFound() {
  


  return (
    <main className="home-background">
      <section className=" " 
      //style={{backgroundImage: `url("assets/back-plus.svg")`}} 
      >



          <div className="project-type-page-back" >
            <section className="project-type-section fade-in">
              <div className="project-type-zone-1" style={{backgroundImage: `url("../assets/+-crossover.gif")`, opacity: 0.15, minHeight: 740, zIndex: '0'}}> 
              </div>
            </section>
          </div>
        <div className="zone-1 fade-in" style={{position: `absolute`, top: '60px', zIndex: '3', backgroundColor: '#ff000000'}}>
          <Row className="zone-1-inner">
       
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}>
              <h1 id="intro">
                
                Page Not Found.
                
              </h1>
            </Col>
            <Row style={{marginTop: '40px'}} >            
              <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
              >
          

            
                <Link 
                  to={"/portfolio"} 
                  key={"portfolio"}
                  className="outlink"
                  >
                  <div >
                    <span className="outlink-text">
                      See All Projects
                    </span>
                    <span> </span>
                    <span className="outlink-arrow">
                    {'>'}
                    </span>
                  </div>
                </Link>

                <Link 
                  to={"/"} 
                  key={"home"}
                  className="outlink"
                  >
                  <div >
                    <span className="outlink-text">
                      Home
                    </span>
                    <span> </span>
                    <span className="outlink-arrow">
                    {'>'}
                    </span>
                  </div>
                </Link>
                  
              </Col>
              

            </Row>
          </Row>
        </div>

      
      </section>
    </main>
 

  );
}