import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "client.js";

import {plus} from "components/plus";

import { NavHashLink } from 'react-router-hash-link';

import LookDown from 'icons/look-down.js';

import { Row, Col } from 'react-simple-flex-grid';

import "react-simple-flex-grid/lib/main.css";

import HeaderBar from "components/header-bar";

import BlockContent from "@sanity/block-content-to-react";

import TestimonialsMap from "components/testimonials-map";

import imageUrlBuilder from "@sanity/image-url";
import "./index.scss";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}




export default function About() {

  const [aboutpage, setAboutpage] = useState(null);
  const [approachpage, setApproachpage] = useState(null);
  const [leadershippage, setLeadershippage] = useState(null);
  const [peopleData, setPeopleData] = useState(null);

  const scrollWithOffset = (el) => {
    window.scrollTo({top: window.innerHeight+134, left: 0, behavior: 'smooth'});
  }

  useEffect(() => {
    sanityClient.fetch(`*[_type == "aboutpage"]{
        header,
        body,
        image{
          asset->{
            _id,
            url
          }
        },
      }
      `)
      .then((data) => setAboutpage(data))
      .catch(console.error);

  }, [null]);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "approachpage"]{
        intro,
        body,
        banner{
          asset->{
            _id,
            url
          }
        },
      }
      `)
      .then((data) => setApproachpage(data))
      .catch(console.error);

  }, []);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "leadershippage"]{
        intro,
        banner{
          asset->{
            _id,
            url
          }
        },
      }
      `)
      .then((data) => setLeadershippage(data))
      .catch(console.error);

  }, []);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "people"] | order(order asc){
        name,
        position,
        accreditations,
        image{
          asset->{
            _id,
            alt,
            url
          }
        },
        bio,
        slug,
      }
      `)
      .then((data) => setPeopleData(data))
      .catch(console.error);

  }, []);


  if ( !aboutpage | !approachpage | !leadershippage | !peopleData ) return (
    <div className="about-page-back" > 
     <section className="portfolio-section"
      style={{backgroundImage: `url("assets/back-plus.svg")`}} 
      >
     </section>
  </div>

  );



  const foundersData = peopleData.filter(people => people.position === 'founder');

  const partnerPositions = ['partner', 'juniorpartner'];
  const partnersData = peopleData.filter(people => partnerPositions.includes(people.position));

  const partnerSeniorPositions = ['seniorpartner'];
  const partnersSeniorData = peopleData.filter(people => partnerSeniorPositions.includes(people.position));











  return (
    <main className="about-page-back">
      <section className="portfolio-section fade-in">



    

          <HeaderBar className="header-bar-sticky" title="Introduction" color="light"/>

     


          <div className="zone-1 fade-in">
            <Row className="zone-1-inner-about">
              <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}>
                <h1 className="profile-description-h1">
                  { aboutpage ? 
                  (aboutpage[0].header)
                  : 
                  ("Understanding Clients' Needs.")
                  }
                </h1>
              </Col>
              <Row>
              <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 0, offset: 0 }}
                xl={{ span: 0, offset: 0 }}>
                  <div className="grid-zone">
                    {plus}
                  </div>
                  <div  className="grid-image-above">
                    { aboutpage ? 
                    <img 
                    
                      src={urlFor(aboutpage[0].image).url()} 
                      alt="image"
                    />
                    : <img src="./assets/_DSC518+8-asset.jpg"/>
                    }
                  </div>
                </Col>
                <Col
                  xs={{ span: 10, offset: 0 }}
                  sm={{ span: 10, offset: 1 }}
                  md={{ span: 8, offset: 2 }}
                  lg={{ span: 4, offset: 0 }}
                  xl={{ span: 4, offset: 0 }}
                >
                  { aboutpage ? 
                    <BlockContent 
                      blocks={aboutpage[0].body} 
                      projectId="cv0gtuav" 
                      dataset="production"
                    />
                  : 
                    <div></div>
                  }

                  <div >
                    <Link 
                      to={"/portfolio"} 
                      key={"portfolio"}
                      className="outlink"
                      >
                      <span className="outlink-text">
                        See All Projects
                      </span>
                      <span> </span>
                      <span className="outlink-arrow">
                        
                      </span>
                    </Link>
                  </div>
                    
                </Col>
                <Col
                xs={{ span: 0, offset: 0 }}
                sm={{ span: 0, offset: 0 }}
                md={{ span: 0, offset: 0 }}
                lg={{ span: 8, offset: 0 }}
                xl={{ span: 8, offset: 0 }}>
                  <div className="grid-zone">
                    {plus}
                  </div>
                  <div className="grid-image-above" >
                    { aboutpage ? 
                    <img 
                      src={urlFor(aboutpage[0].image).url()} 
                      alt="image"
                    />
                    : <img src="./assets/_DSC518+8-asset.jpg"/>
                    }
                  </div>
                </Col>
              </Row>
              <div className="look-down">
    


                <NavHashLink 
                  
                  to="/about#approach" 
                  scroll={scrollWithOffset}
                >
                              <LookDown/>
                </NavHashLink>
              </div>
            </Row>
          </div>

          <div id="approach" className="banner" >
            { approachpage ? 
            <img 
              src={urlFor(approachpage[0].banner).url()} 
              alt="image"
            />
            : <img  src="assets/plan-banner.png" />
            }
          </div>

          <div className="portfolio-zone-1 approach-bg-img "  style={{backgroundImage: `url('/assets/_DSC5705+8.png')`}}>
            {/* <div className="approach-bg-img" style={{backgroundImage: `url('/assets/_DSC5705+8.jpg')`}}></div> */}
            <div className="header-and-desc">
              <HeaderBar className="header-bar-sticky approach-header" title="Approach" color="light" />
              <div className="description-about approach-description ">
                <div className="description-about-inner light-text">
                  { approachpage ? 
                      <BlockContent 
                        blocks={approachpage[0].intro} 
                        projectId="cv0gtuav" 
                        dataset="production"
                      />
                    : 
                      <div></div>
                    }
                </div>
              </div>
            </div>
            <div className="featured-panel-approach" >
                { approachpage ? 
                  <BlockContent 
                    blocks={approachpage[0].body} 
                    projectId="cv0gtuav" 
                    dataset="production"
                  />
                : 
                  <div></div>
                }
    
            </div>
          </div>

          <div id="leadership" className="banner" >
            { leadershippage ? 
            <img 
              src={urlFor(leadershippage[0].banner).url()} 
              alt="image"
            />
            : <img  src="assets/aboutus_people.jpg" />
            }
          </div>
          <div className="portfolio-zone-1 leadership-bg press-releases-bg-img" >
            <div className="header-and-desc">
              <HeaderBar className="header-bar-sticky leadership-header" title="Leadership" color="light"/>
              <div className="description-about ">
                <div className="description-about-inner dark-text">
                  { leadershippage ? 
                    <BlockContent 
                      blocks={leadershippage[0].intro} 
                      projectId="cv0gtuav" 
                      dataset="production"
                    />
                  : 
                    <div></div>
                  }
                </div>
              </div>
            </div>
            

{/* this is a copied bit from project type 1  */}
            <div className="featured-panel fade-in-more">
            <div className="description-about-inner"><h1>Principals</h1></div>
              <Row gutter={0}>
                {foundersData && foundersData.map((people, index) => (
                  
                  <Col
                  
                    className="col-visible"
                    xs={{ span: 12 }} sm={{ span: 5 }} md={{ span: 6 }}
                    lg={{ span: 4 }} xl={{ span: 4 }}
                    key={people._id}
                  >
                    
                    <div className="project-card-wrap-wrap">
                      <Link 
                        className="project-card-wrap pcw-max-width"
                        to={"/people/" + people.slug.current} 
                        key={people.slug.current}
                        >
                        <div
                          className="card-img"
                          key={people._id}
                        >
                          <img 
                            src={urlFor(people.image).url()}
                            alt={people.image.alt}
                            key={people._id}
                            className="main-image-project-type firm-card-image"
                          />
                        </div>
                        <div className="card-description firm-cards">
                            <h3>
                              {people.name}
                            </h3>
                            <h4>
                              {people.accreditations}
                            </h4>
                          </div> 
                      </Link>
                    </div>
                    
                  </Col>
                ))}
              </Row>

            <div className="description-about-inner"><h1>Partners</h1></div>
              <Row gutter={0}>
                {partnersSeniorData && partnersSeniorData.map((people, index) => (
                  
                  <Col
                  
                    className="col-visible"
                    xs={{ span: 12 }} sm={{ span: 5 }} md={{ span: 6 }}
                    lg={{ span: 4 }} xl={{ span: 4 }}
                    key={people._id}
                  >
                    
                    <div className="project-card-wrap-wrap">
                      <Link 
                        className="project-card-wrap pcw-max-width"
                        to={"/people/" + people.slug.current} 
                        key={people.slug.current}
                        >
                        <div
                          className="card-img"
                          key={people._id}
                        >
                          <img 
                            src={urlFor(people.image).url()}
                            alt={people.image.alt}
                            key={people._id}
                            className="main-image-project-type firm-card-image"
                          />
                        </div>
                        <div className="card-description firm-cards">
                            <h3>
                              {people.name}
                            </h3>
                            <h4>
                              {people.accreditations}
                            </h4>
                          </div> 
                      </Link>
                    </div>
                    
                  </Col>
                ))}
              </Row>
              <Row gutter={0}>
                {partnersData && partnersData.map((people, index) => (
                  
                  <Col
                  
                    className="col-visible"
                    xs={{ span: 12 }} sm={{ span: 5 }} md={{ span: 6 }}
                    lg={{ span: 4 }} xl={{ span: 4 }}
                    key={people._id}
                  >
                    
                    <div className="project-card-wrap-wrap">
                      <Link 
                        className="project-card-wrap pcw-max-width"
                        to={"/people/" + people.slug.current} 
                        key={people.slug.current}
                        >
                        <div
                          className="card-img"
                          key={people._id}
                        >
                          <img 
                            src={urlFor(people.image).url()}
                            alt={people.image.alt}
                            key={people._id}
                            className="main-image-project-type firm-card-image"
                          />
                        </div>
                        <div className="card-description firm-cards">
                            <h3>
                              {people.name}
                            </h3>
                            <h4>
                              {people.accreditations}
                            </h4>
                          </div> 
                      </Link>
                    </div>
                    
                  </Col>
                ))}
              </Row>


                  
            </div>

{/* End of Copied bit from Project Type 1  */}

          </div>






          <div id="testimonials" className="banner" >
            <img  src="assets/section-banner.png" />
          </div>
          <div className="portfolio-zone-1 " id="testimonials" 
              style={{backgroundImage: `url(\"assets/back-plus.svg\")`}}
              >
            <div className="header-and-desc">
              <HeaderBar className="header-bar-sticky approach-header" title="Testimonials" color="light"/>
              <div className="description-testimonials ">
                <div className="description-testimonials-inner">
                    <h2>
                      Our commitment to our clients is clear and simple: <br/><br/>
                      Smart solutions, good service, cost effective design.
                    </h2>
                    <p> 
                      Establishing a long-term relationship is what <span className="nowrap">Arcari + Iovino Architects</span> strive for in every commission we receive. Listening to the client first, before designing solutions allows us to understand the thier needs and goals and treat each project uniquely. Our client lists include many repeat and long-term partners, which is a testament to the high level of service we provide.
                    </p>
                </div>
              </div>
            </div>
            <div className="featured-panel" >
              <TestimonialsMap/>
            </div>
          </div>
   
      </section>
    </main>
  );
}