import React from 'react';

function CaretDown(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"+"}</title>
      <path
        fill="#B5D470"
        fillRule="nonzero"
        d="M10.174 13.813l6.665-6.667v-.378l-1.323-1.324h-.378l-4.991 4.992h-.379l-4.99-4.991H4.4L3.076 6.768v.378l6.668 6.667z"
      />
    </svg>
  )
}

export default CaretDown