import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "client.js";


import { Row, Col } from 'react-simple-flex-grid';
import LookDown from 'icons/look-down.js';

import { NavHashLink } from 'react-router-hash-link';

import "react-simple-flex-grid/lib/main.css";

import HeaderBar from "components/header-bar";

import BlockContent from "@sanity/block-content-to-react";

import imageUrlBuilder from "@sanity/image-url";
import "./index.scss";
import Testimonials from "pages/testimonials";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}



export default function Portfolio() {
  const [projectData, setProject] = useState(null);
  const [portfoliopage, setPortfoliopage] = useState(null);

  const scrollABit = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -94; 

    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 

  }

  useEffect(() => {
    sanityClient.fetch(`*[_type == "project" && featured == true] | order(order asc){
        title,
        location,
        slug,
        imagesGallery,
      }
      `)
      .then((data) => setProject(data))
      .catch(console.error);

  }, []);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "portfoliopage"]{
        intro,
      }
      `)
      .then((data) => setPortfoliopage(data))
      .catch(console.error);

  }, []);

  if (!projectData | !portfoliopage) return <div className="portfolio-page-back" 
  style={{backgroundImage: `url("assets/back-plus.svg")`}}
  > 
  </div>;
  
  console.log(portfoliopage);
  return (
    <main className="portfolio-page-back">
      <section className="portfolio-section fade-in">



          <div className="portfolio-zone-1" id="portfolio" style={{backgroundImage: `url("assets/back-plus.svg")`}}>
            <div className="header-and-desc min-height-for-hash">
              <HeaderBar className="header-bar-sticky" title="Portfolio" color="dark"/>
              <div className="description-portfolio ">
                <div className="description-portfolio-inner">
                    { portfoliopage ? 
                      <BlockContent 
                        blocks={portfoliopage[0].intro} 
                        projectId="cv0gtuav" 
                        dataset="production"
                      />
                    : 
                      <div></div>
                    }
                    <h2>
                      Explore Project Types
                    </h2>
                    <Row>
                      <Col
                        
                        className="col-visible"
                        xs={{ span: 12 }} sm={{ span: 4 }} md={{ span: 12 }}
                        lg={{ span: 12 }} xl={{ span: 12 }}
                      >
                         <Link 
                          to={"/category/libraries"} 
                          key={"libraries"}
                          className="outlink"
                          >
                          <div className="outlink-back">
                            <span className="outlink-text">
                              Libraries
                            </span>
                            <span> </span>
                            <span className="outlink-arrow">
                              >
                            </span>
                          </div>
                        </Link>
                        
                      </Col>
                      <Col
                        
                        className="col-visible"
                        xs={{ span: 12 }} sm={{ span: 4 }} md={{ span: 12 }}
                        lg={{ span: 12 }} xl={{ span: 12 }}
                      >
                        <Link 
                          to={"/category/government"} 
                          key={"government"}
                          className="outlink"
                          >
                          <div className="outlink-back">
                            <span className="outlink-text">
                              Government
                            </span>
                            <span> </span>
                            <span className="outlink-arrow">
                              >
                            </span>
                          </div>
                        </Link>
                      </Col>
                      <Col
                        
                        className="col-visible"
                        xs={{ span: 12 }} sm={{ span: 4 }} md={{ span: 12 }}
                        lg={{ span: 12 }} xl={{ span: 12 }}
                      >
                      <Link 
                        to={"/category/corporate"} 
                        key={"corporate"}
                        className="outlink"
                        >
                        <div className="outlink-back">
                          <span className="outlink-text">
                            Corporate
                          </span>
                          <span> </span>
                          <span className="outlink-arrow">
                            >
                          </span>
                        </div>
                      </Link>
                      </Col>
                      <Col
                        
                        className="col-visible"
                        xs={{ span: 12 }} sm={{ span: 4 }} md={{ span: 12 }}
                        lg={{ span: 12 }} xl={{ span: 12 }}
                      >
                      <Link 
                        to={"/category/religious"} 
                        key={"religious"}
                        className="outlink"
                        >
                        <div className="outlink-back">
                          <span className="outlink-text">
        
                            Religious
                          </span>
                          <span> </span>
                          <span className="outlink-arrow">
                            >
                          </span>
                        </div>
                      </Link>
                      </Col>
                      <Col
                        
                        className="col-visible"
                        xs={{ span: 12 }} sm={{ span: 4 }} md={{ span: 12 }}
                        lg={{ span: 12 }} xl={{ span: 12 }}
                      >
                      <Link 
                        to={"/category/residential"} 
                        key={"residential"}
                        className="outlink"
                        >
                        <div className="outlink-back">
                          <span className="outlink-text">
            
                            Residential
                          </span>
                          <span> </span>
                          <span className="outlink-arrow">
                            >
                          </span>
                        </div>
                      </Link>
                      </Col>
                      <Col
                        
                        className="col-visible"
                        xs={{ span: 12 }} sm={{ span: 4 }} md={{ span: 12 }}
                        lg={{ span: 12 }} xl={{ span: 12 }}
                      >
                      <Link 
                        to={"/category/educational"} 
                        key={"educational"}
                        className="outlink"
                        >
                        <div className="outlink-back">
                          <span className="outlink-text">
        
                            Educational
                          </span>
                          <span> </span>
                          <span className="outlink-arrow">
                            >
                          </span>
                        </div>
                      </Link>
                      </Col>
                    </Row>
                   
                   
             
                </div>

                <NavHashLink   
                  to="/portfolio/#testimonials" 
                  scroll={scrollABit}
                  >
                    <LookDown className="look-down-side"/>

                  </NavHashLink>
              </div>
            </div>
            <div className="featured-panel featured-projects">
              <Row gutter={0}>
                {projectData && projectData.map((project, index) => (
                  
                  <Col
                  
                    className="col-visible"
                    xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}
                    lg={{ span: 4 }} xl={{ span: 4 }}
                  >
                    <div className="project-card-wrap-wrap">
                      <Link 
                        className="project-card-wrap"
                        to={"/project/" + project.slug.current} 
                        key={project.slug.current}
                        >
                        <div
                          className="card-img"
                          key={index}
                        >
                          <img 
                            loading="lazy"
                            src={urlFor(project.imagesGallery[0])
                              .url()}
                            alt={project.imagesGallery[0].alt}
                            key={index}
                            className="main-image-portfolio"
                          />
                        </div>
                        <div className="card-description">
                            <h3>
                              {project.title}
                            </h3>
                            <h4>
                              {project.location}
                            </h4>
                          </div> 
                      </Link>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>


          <Testimonials id="testimonials"/>

   
      </section>
    </main>
  );
}