import React, { useEffect, useState } from "react";

import sanityClient from "client.js";
import BlockContent from "@sanity/block-content-to-react";
import {plus} from 'components/plus';

import { Row, Col } from 'react-simple-flex-grid';

import "react-simple-flex-grid/lib/main.css";



import "./index.scss";


export default function TestimonialsMap() {
  const [testimonials, setTestimonials] = useState(null);

  
  useEffect(() => {
    sanityClient.fetch(`*[_type == "testimonial"] | order(associated asc){
      quote,
      nametitle,
      associated,
    }
    `)
    .then((data) => setTestimonials(data))
    .catch(console.error);
    
  }, []);

    return (
      
<Row gutter={0} >
      {testimonials && testimonials.map((testimonial, index) => (
        
        <Col
        
          className="col-visible"
          xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}
          lg={{ span: 12}} xl={{ span: 12 }}
          key={testimonials[index].nametitle}
        >
      
              <div 
                // style={{backgroundImage: `url("assets/back-plus.svg")`}} 
                className={`testimonial ${index % 4 === 0 ? 'fourths' : index % 4 === 1 ? 'thirds' : index % 4 === 2 ? 'seconds' : 'ones'}`}>
                <div className="testimonial-inner">
                  <div>
                    <BlockContent
                      blocks={testimonials[index].quote}
                      projectId={sanityClient.clientConfig.projectId}
                      dataset={sanityClient.clientConfig.dataset}
                      />
                  </div>
                  <h4>
                    {testimonials[index].nametitle}<span> – </span>
                    {testimonials[index].associated}
                  </h4>
                </div>
              </div> 
          

        </Col>
      ))}
    </Row>

  );
}